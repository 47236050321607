import React from "react"

const counterStyle = {
  /* styles skipped for brevity */
}

export default class ModelViewer extends React.Component {
  static defaultProps = {
    src: "",
    alt: "",
    orbit: "0deg 90deg 80%",
  }

  render() {
    return (
      <model-viewer
        class="model-viewer"
        camera-orbit={this.props.orbit}
        loading="eager"
        interaction-prompt-threshold="0"
        camera-controls
        alt={this.props.alt}
        src={this.props.src}
      ></model-viewer>
    )
  }
}
